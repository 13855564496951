@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-alice-carousel/lib/alice-carousel.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&family=Lora:wght@400;500;700&family=Tangerine:wght@400;700&display=swap');


* {
  font-family: 'Abril Fatface';
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  border-width: 0 !important;
}

.test {
  display: flex;
  align-items: center;
  /* width: 100vw; */
  justify-content: space;
}

.drop {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: center;
}

.carousel-caption {
  right: auto !important;
  top: 40%;
  text-align: start !important;

}

.carimage {
  background-size: cover;
  background-position: top;
}

.phone {
  color: #3f87b9 !important;
}


.missionText {
  font-family: 'Tangerine', cursive;
  font-weight: 700;
  font-size: 4rem;

}

.missiongp {
  font-family: 'Lato', sans-serif;
} 

@media only screen and (max-width: 639px) {
  .test {
    width: 100vw;
    justify-content: space-between;
  }
  .carimage {
    height: 45vh;
    background-size:cover;
    background-position: center;
  }
  .program-card {
    height: 20vh !important;
    width: 20vh !important;
  }
  

}



